export class AppConstants {
  public static readonly ACCESS_TOKEN = 'userToken'
  public static readonly API_URL = process.env.REACT_APP_API_BASE_URL || '/api'
  public static readonly CURRENTROLE_TOKEN = 'userCurrentRole'
}

export enum Action_Type {
  SELECTED_PLAN_INFO = 'Selected_Plan_Info',
  SELECTED_SLOT = 'Selected_Slot',
  SELECTED_PLAN = 'Selected_Plan',
  AVAILABLE_PLAN = 'Available_Plan',
  SLOT_BOOKED_ALREADY = 'Slot_Booked_Already',
  PAYMENT_DONE = 'Payment_Done',
}

export enum Referral_Flow_Action_Type {
  SELECTED_SLOT_REFERRAL_FLOW = 'Selected_Slot_Referral_Flow',
  SLOT_BOOKED_ALREADY_REFERRAL_FLOW = 'Slot_Booked_Already_Referral_Flow',
}

export enum Offers_Flow_Action_Type {
  VISIT_COUNT_OFFERS_FLOW = 'Visit_Count_Offers_Flow',
  SELECTED_SLOT_OFFERS_FLOW = 'Selected_Slot_Offers_Flow',
  SLOT_BOOKED_ALREADY_OFFERS_FLOW = 'Slot_Booked_Already_Offers_Flow',
  PAYMENT_PLAN_OFFERS_FLOW = 'Payment_Plan_Offers_Flow',
  PURCHASED_PLAN_OFFERS_FLOW = 'Purchased_Plan_Offers_Flow',
  PAYMENT_DONE_OFFERS_FLOW = 'Payment_Done_Offers_Flow',
  TIME_LEFT_OFFERS_FLOW = 'Time_Left_Offers_Flow',
}
