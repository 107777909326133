import React, { useEffect, useState } from 'react'
import {
  Button,
  Dialog,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material'
import axios from 'axios'
import ReactGA from 'react-ga4'
import { useNavigate, useParams } from 'react-router-dom'
import Footer from 'components/Footer'
import Header from 'components/Header'
import TextField from 'components/shared/textField'
import {
  SetPaymentDoneOfferFlow,
  SetPaymentPlansOfferFlow,
  SetPurchasedPlanOfferFlow,
  SetSelectedSlotOfferFlow,
  SetSlotBookedAlreadyOfferFlow,
  SetTimeLeftOfferFlow,
  SetVisitCountOfferFlow,
} from 'redux/Offers/persist'
import { useAppDispatch } from 'redux/store'
import { StatusEnum } from 'redux/types'
import { EMAIL_REGEX } from 'regexConstants'
import { decodeToken } from 'utils/decodeToken'
import styles from './Signup.module.scss'
import style from './VerifyOtp.module.scss'
import 'react-phone-input-2/lib/material.css'
import { AppConstants } from '../../../AppConstants/AppConstants'
import useAuth from '../../../hooks/useAuth'

interface InitialValues {
  firstname: string
  lastname: string
  email: string
  // gender: string
  phone: string
}

const Signup: React.FC = () => {
  const initialValues: InitialValues = {
    firstname: '',
    lastname: '',
    email: '',
    // gender: '',
    phone: '',
  }

  const styless: any = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 260,
    height: 170,
    overflow: 'hidden',
    backgroundColor: '#050624',
    color: 'white',
    borderRadius: '8px',
    p: 4,
  }
  const { id } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [data, setData] = useState(initialValues)
  // const [dobValue, setDobValue] = useState<Date | any>(null)
  const [OTPScreen, setOTPScreen] = useState(false)
  const [otpError, setOtpError] = useState(false)
  const [disableVerifyBtn, setDisableVerifyBtn] = useState(true)
  const [code, setCode] = useState('')
  const [firstNameError, setFirstNameError] = useState(false)
  const [lastNameError, setLastNameError] = useState(false)
  const [modal, setModal] = useState(false)
  const [verifyStatus, setVerifyStatus] = useState<StatusEnum>(StatusEnum.Idle)
  // const [dobError, setDobError] = useState(false)
  // const [genderError, setGenderError] = useState(false)
  const [phoneError, setPhoneError] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const [errorType, setErrorType] = useState('')
  // const [wrongNumber, setWrongNumber] = useState(false)
  const [userId, setUserId] = useState('')

  const { currentRole } = useAuth()

  async function getPlans() {
    try {
      const response: any = await axios.get(`${AppConstants.API_URL}/payment-plan?campaign=${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          'x-platform-id': process.env.REACT_APP_PLATFORM_ID || '',
        },
      })
      if (response && response?.data && response?.data?.data) {
        console.log(response?.data?.data, 'plans')
        dispatch(SetPaymentPlansOfferFlow(response?.data?.data))
      } else if (response?.data?.statusCode === 401) {
        // navigate('/login')
        console.log('error')
        //call reset login
      }
    } catch (error) {
      console.error(error)
    }
  }

  const onContinue = async () => {
    let auth = false

    if (!data.firstname) {
      setFirstNameError(true)
      setErrorType('fieldError')
      auth = true
    } else {
      setFirstNameError(false)
    }
    if (!data.lastname) {
      setLastNameError(true)
      setErrorType('fieldError')
      auth = true
    } else {
      setLastNameError(false)
    }

    // if (dobError) {
    //   auth = true
    //   setDobError(true)
    //   setErrorType('Incorrect-Dob')
    // } else if (dobValue === '' || dobValue === null) {
    //   auth = true
    //   setValue(data.dob)
    //   setErrorType('Incorrect-Dob')
    //   setDobError(true)
    // } else {
    //   const formattedDob = format(dobValue, 'yyyy-MM-dd')
    //   if (calculateDob(formattedDob) >= 18) {
    //     setValue(dobValue)
    //     setDobError(false)
    //   } else {
    //     auth = true
    //     setErrorType('under_18')
    //   }
    // }
    if (data.phone.replace(/-/g, '').length < 10) {
      setPhoneError(true)
      setErrorType('Incorrect-phoneFormat')
      auth = true
    } else {
      setPhoneError(false)
    }
    // if (!data.gender) {
    //   setGenderError(true)
    //   setErrorType('GenderError')
    //   auth = true
    // } else {
    //   setGenderError(false)
    // }

    if (!data.email) {
      setEmailError(true)
      setErrorType('fieldError')
    } else if (!EMAIL_REGEX.test(data.email.toLowerCase())) {
      setEmailError(true)
      setErrorType('Incorrect-email')
      auth = true
    } else {
      setEmailError(false)
    }

    if (!auth) {
      // const formattedDob = format(dobValue, 'yyyy-MM-dd')
      // setData({ ...data, dob: formattedDob })

      let response: any = await axios
        .post(
          AppConstants.API_URL + '/referral/lead/signup',
          {
            ...data,
            email: data.email.toLowerCase(),
            phone: '+1' + data.phone.replace(/-/g, ''),
            referralCode: id,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('userToken')}`,
              'x-platform-id': process.env.REACT_APP_PLATFORM_ID || '',
            },
          },
        )
        .then((res) => res)
        .catch((err) => err.response)

      console.log(response, 'jjjjjjjjres')

      if (response && response.data && response.data.code === 'success') {
        setErrorType('')
        setOTPScreen(true)
        setUserId(response.data.data.id)

        if (id === 'facebook') {
          //@ts-ignore
          fbq('track', 'Lead')
        } else {
          ReactGA.event('conversion', {
            send_to: 'AW-11337579902/m3hPCJKL4LgZEP76lp4q',
          })
        }
      } else if (response && response.data && response.data.code === 'user_already_exists') {
        setErrorType('userExists')
      } else if (response && response.data && response.data && response.data.code === 'email exists') {
        setErrorType('emailExists')
      } else if (response && response.data && response.data.code === 'phone_number_already_exists') {
        setErrorType('mobileExists')
      } else if (response && response.data && response.data.code >= 400 && response.data.code <= 599) {
        setErrorType('Error_Occurred')
      } else if (response && response.data && response.data.statusCode >= 400 && response.data.statusCode <= 599) {
        setErrorType('Error_Occurred')
      } else {
        setErrorType('unknown')
      }
    }
  }

  const sendOtp = async () => {
    if (data.phone.length >= 12) {
      setDisableVerifyBtn(true)
      const response: any = await axios
        .post(AppConstants.API_URL + '/referral/lead/signup/resend-otp', {
          phone: '+1' + data.phone.replace(/-/g, ''),
        })
        .then((res) => res)
        .catch((err) => err.response)
      if (
        response &&
        response?.data?.code === 'success' &&
        response?.data?.message === 'Verification code sent successfully'
      ) {
        setOTPScreen(true)
      } else if (response && response?.data?.message === 'Phone number is not found') {
        setErrorType('Not_User')
      } else if (response && response.data && response.data.code >= 400 && response.data.code <= 599) {
        setErrorType('Error_Occurred')
      } else if (response && response.data && response.data.statusCode >= 400 && response.data.statusCode <= 599) {
        setErrorType('Error_Occurred')
      }
    } else {
      setErrorType('formatInvalid')
      setDisableVerifyBtn(false)
    }
  }

  const onVerify = async () => {
    setDisableVerifyBtn(true)
    if (code === '') {
      setOtpError(true)
      setErrorType('Empty')
    } else {
      console.log('lll')
      try {
        const response: any = await axios
          .post(AppConstants.API_URL + '/referral/lead/verify-otp', {
            phone: '+1' + data.phone.replace(/-/g, ''),
            code: code,
            id: userId,
          })
          .then((res) => res)
          .catch((err) => err.response)

        if (response && response.data && response.data.code === 'success') {
          localStorage.setItem(AppConstants.ACCESS_TOKEN, response?.data?.data?.accessToken)
          let localToken = response.data?.data?.accessToken
          let tokenParts = localToken.split('.')
          let tokenPayload = tokenParts[1]
          let tokenPayloadStr = atob(tokenPayload)
          let decodedToken: any = JSON.parse(tokenPayloadStr)
          let tempCurrentRole: any = ''
          if (decodedToken) {
            if (decodedToken.roles && decodedToken.roles.length === 1) {
              tempCurrentRole = decodedToken.roles[0]
            }
          } else {
            tempCurrentRole = null
          }
          localStorage.setItem(AppConstants.CURRENTROLE_TOKEN, tempCurrentRole)
          // if (tempCurrentRole === 'PATIENT') {
          //   navigate('/add-photo')
          // } else {
          //   navigate('/signup')
          // }
          //@ts-ignore

          if (id === 'facebook') {
            //@ts-ignore
            fbq('track', 'SubmitApplication')
          } else {
            ReactGA.event('conversion', {
              send_to: 'AW-11337579902/m3hPCJKL4LgZEP76lp4q',
            })
          }

          setVerifyStatus(StatusEnum.Success)
        } else if (response && response.data && response.data.code === 'user_already_exists') {
          // setOtpError(true)
          setModal(true)
          // setErrorType('userExists')
          setDisableVerifyBtn(false)
        } else if (response && response.data && response.data.code === 'invalid_value') {
          setOtpError(true)
          setErrorType('Invalid')
          setDisableVerifyBtn(false)
        } else if (response && response.data && response.data.code === 'code_expired') {
          setOtpError(true)
          setErrorType('Code_Expired')
          setDisableVerifyBtn(false)
        } else if (response && response.data && response.data.code === 'user_not_found') {
          setErrorType('Not_User')
          setDisableVerifyBtn(false)
        } else if (response && response.data && response.data.statusCode >= 400 && response.data.statusCode <= 599) {
          setErrorType('Error_Occurred')
          setDisableVerifyBtn(false)
        }
      } catch (error) {
        console.error('An error occurred:', error)
      }
    }
  }

  const handleKeyDown = (e: any, nextFieldName: any) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      const nextField = e.target.form.elements[nextFieldName]
      if (nextField) {
        nextField.focus()
      }
    }
  }

  async function getProfile() {
    try {
      const response: any = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/auth/me`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          'x-platform-id': process.env.REACT_APP_PLATFORM_ID || '',
        },
      })

      dispatch(
        SetVisitCountOfferFlow({
          onDemandCount: response?.data?.data?.onDemandCount ?? null,
          subscriptionCount: response?.data?.data?.subscriptionCount ?? null,
          previousVisits: response?.data?.data?.previousVisits ?? null,
        }),
      )
      dispatch(SetPaymentDoneOfferFlow(response?.data?.data?.initialPayment))
      if (
        response &&
        response?.data.code === 'success' &&
        response?.data?.data?.dob === null &&
        response?.data?.data?.previousVisits === false
      ) {
        getPlans()
        navigate(`/offers/${id}/dob`)
      } else if (
        response &&
        response?.data.code === 'success' &&
        response?.data?.data?.dob !== null &&
        response?.data?.data?.previousVisits === false
      ) {
        getPlans()
        navigate(`/offers/${id}/physical-visit`)
      } else if (response && response?.data.code === 'success' && response?.data?.data?.previousVisits === true) {
        setModal(true)
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (verifyStatus === StatusEnum.Success) {
      let localToken = localStorage.getItem(AppConstants.ACCESS_TOKEN)
      if (localToken) {
        const isPatient = decodeToken(localToken)
        if (isPatient && isPatient === 'PATIENT') {
          getProfile()
        } else {
          navigate(`/offers/${id}/download`)
        }
      }
    }
  }, [verifyStatus])

  useEffect(() => {
    if (id === 'google') {
      ReactGA.event('page_view', {
        page_title: 'Offers|Signup',
        page_location: `/offers/${id}/signup`,
      })
    }
  }, [id])

  useEffect(() => {
    if (id === 'facebook') {
      //@ts-ignore
      fbq('track', 'ViewContent')
    }
  }, [id])

  useEffect(() => {
    dispatch(SetVisitCountOfferFlow(null))
    dispatch(SetSelectedSlotOfferFlow(null))
    dispatch(SetPaymentPlansOfferFlow(null))
    dispatch(SetPurchasedPlanOfferFlow(null))
    dispatch(SetPaymentDoneOfferFlow(false))
    dispatch(SetSlotBookedAlreadyOfferFlow(false))
    dispatch(SetTimeLeftOfferFlow(null))
  }, [])

  if (!OTPScreen) {
    return (
      <div className={styles.container}>
        <Header headerText={!OTPScreen ? "Let's Create Your Profile" : ''} showBackButton={false}></Header>
        <div className={styles.content}>
          <form
            onSubmit={(e) => {
              e.preventDefault()
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  autoFocus
                  sx={
                    firstNameError
                      ? {
                          backgroundImage: "url('images/error.svg')",
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'right 15px center',
                        }
                      : {
                          '& .MuiInputBase-input': {
                            textTransform: 'capitalize',
                          },
                        }
                  }
                  style={{ marginTop: '5px' }}
                  placeholder="Enter first name"
                  label="First Name"
                  name="firstname"
                  value={data.firstname}
                  error={firstNameError}
                  onChange={(e) => {
                    setData({ ...data, firstname: e.target.value })
                    if (data.firstname.length < 0) {
                      setFirstNameError(true)
                    } else {
                      setFirstNameError(false)
                    }
                  }}
                  onKeyDown={(e) => handleKeyDown(e, 'lastname')}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  sx={
                    lastNameError
                      ? {
                          backgroundImage: "url('images/error.svg')",
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'right 15px center',
                          textTransform: 'capitalize',
                        }
                      : {
                          '& .MuiInputBase-input': {
                            textTransform: 'capitalize',
                          },
                        }
                  }
                  placeholder="Enter last name"
                  value={data.lastname}
                  label="Last Name"
                  name="lastname"
                  error={lastNameError}
                  onChange={(e) => {
                    setData({ ...data, lastname: e.target.value })
                    if (data.lastname.length < 0) {
                      setLastNameError(true)
                    } else {
                      setLastNameError(false)
                    }
                  }}
                  onKeyDown={(e) => handleKeyDown(e, 'email')}
                />
              </Grid>
              {/* <Grid item xs={12}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateField
                    name="datefield"
                    value={value}
                    sx={
                      dobError
                        ? {
                            backgroundImage: "url('images/error.svg')",
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'right 15px center',
                          }
                        : {}
                    }
                    onError={(error: any) => {
                      if (error) {
                        setDobError(true)
                      } else {
                        setDobError(false)
                      }
                    }}
                    onChange={(value: any) => {
                      setDobValue(value)
                      setDobError(false)
                    }}
                    label="Date of Birth"
                    disableFuture
                    onKeyDown={(e) => handleKeyDown(e, 'email')}
                  ></DateField>
                </LocalizationProvider>
              </Grid> */}
              {/* <Grid item xs={12}>
                <FormControl style={{ width: '100%' }}>
                  <InputLabel
                    id="gender-label"
                    sx={{
                      '@media (min-width: 600px)': {
                        marginTop: '-0.3rem',
                      },
                    }}
                  >
                    Gender
                  </InputLabel>
                  <Select
                    labelId="gender-label"
                    id="select-gender"
                    value={data.gender}
                    name="gender"
                    label="Gender"
                    error={genderError}
                    onChange={(e) => {
                      setGenderError(false)
                      setData({ ...data, gender: e.target.value })
                    }}
                    onKeyDown={(e) => handleKeyDown(e, 'email')}
                  >
                    <MenuItem value={'male'}>Male</MenuItem>
                    <MenuItem value={'female'}>Female</MenuItem>
                    <MenuItem value={'others'}>Other</MenuItem>
                  </Select>
                </FormControl>
              </Grid> */}
              <Grid
                item
                xs={12}
                sx={{
                  '@media (min-width: 600px)': {
                    marginTop: '0.3rem',
                  },
                }}
              >
                <TextField
                  type="email"
                  sx={
                    emailError
                      ? {
                          backgroundImage: "url('images/error.svg')",
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'right 15px center',
                        }
                      : {}
                  }
                  placeholder="Enter Email"
                  value={data.email}
                  label="Email"
                  name="email"
                  error={emailError}
                  onChange={(e) => {
                    setData({ ...data, email: e.target.value })
                    if (data.email.length < 0) {
                      setEmailError(true)
                    } else {
                      setEmailError(false)
                    }
                  }}
                  onKeyDown={(e) => handleKeyDown(e, 'phone')}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  inputProps={{
                    inputMode: 'numeric',
                    pattern: '[0-9]*',
                    maxLength: 12,
                  }}
                  sx={
                    phoneError
                      ? {
                          backgroundImage: "url('images/error.svg')",
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'right 15px center',
                          textTransform: 'capitalize',
                        }
                      : {
                          '& .MuiInputBase-input': {
                            textTransform: 'capitalize',
                          },
                        }
                  }
                  placeholder="Enter Phone Number"
                  value={data.phone}
                  label="Phone"
                  name="phone"
                  error={phoneError}
                  onChange={(e) => {
                    const formattedValue = e.target.value
                      .replace(/\D/g, '')
                      .slice(0, 10)
                      .replace(/(\d{3})(\d{1,3})?(\d{0,4})?/, (_, first, second, third) => {
                        let result = first
                        if (second) result += '-' + second
                        if (third) result += '-' + third
                        return result
                      })
                    setPhoneError(false)
                    setData({ ...data, phone: formattedValue })
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      onContinue()
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                {errorType && (
                  <div className={styles.errorContainer}>
                    <Typography sx={{ fontSize: '14px', fontWeight: 700 }}>
                      {errorType === 'fieldError' && `Please complete all fields above`}
                      {errorType === 'userExists' && `User already exists`}
                      {errorType === 'emailExists' && `Email already exists`}
                      {errorType === 'mobileExists' && `Mobile number already exists`}
                      {errorType === 'unknown' && `Unknown error`}
                      {errorType === 'Incorrect-phoneFormat' && `Invalid Mobile Number`}
                      {errorType === 'Incorrect-email' && `Invalid Email`}
                      {errorType === 'Incorrect-Dob' && `Invalid Date of Birth`}
                      {errorType === 'GenderError' && `Gender not selected`}
                      {errorType === 'Error_Occurred' && `Oops! something went wrong`}
                      {errorType === 'under_18' && `You must be 18 or older to sign up`}
                    </Typography>
                    <div
                      style={{
                        display: 'flex',
                        flex: '1',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        paddingRight: '12px',
                      }}
                    >
                      <img
                        onClick={() => {
                          setErrorType('')
                        }}
                        style={{ cursor: 'pointer' }}
                        src={'/images/wrong.svg'}
                      ></img>
                    </div>
                  </div>
                )}
              </Grid>
            </Grid>
          </form>
        </div>
        <Footer
          buttonText={'Continue'}
          isLoading={false}
          shouldShowFive={true}
          shouldShowSix={true}
          trackerIndex={1}
          isDisabled={data.firstname === '' || data.lastname === '' || data.email === '' || data.phone === ''}
          buttonId={'sign-in-button'}
          buttonType={'submit'}
          onButtonClick={() => {
            data.firstname === '' || data.lastname === '' || data.email === '' || data.phone === ''
              ? undefined
              : onContinue()
          }}
        ></Footer>
      </div>
    )
  } else {
    return (
      <div className={style.container}>
        {/* <Header headerText={''} showBackButton={false}></Header> */}
        <div className={style.verifyOtpContainer}>
          <p className={style.codeText}>
            Please enter the 6 digit code sent to <br></br>
            <b className={style.boldText}>{data.phone}</b> through SMS.
          </p>
          <form
            onSubmit={(e) => {
              e.preventDefault()
            }}
          >
            <Grid container spacing={2} sx={{ marginBottom: '12px' }}>
              <Grid item xs={12}>
                <TextField
                  autoFocus
                  inputProps={{
                    inputMode: 'numeric',
                    pattern: '[0-9]*',
                    maxLength: 6,
                  }}
                  sx={
                    otpError
                      ? {
                          backgroundImage: "url('images/error.svg')",
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'right 15px center',
                          textTransform: 'capitalize',
                        }
                      : {}
                  }
                  value={code}
                  placeholder="Enter Code"
                  label="Verification Code"
                  name="code"
                  // type="number"
                  error={otpError}
                  onChange={(e) => {
                    setCode(e.target.value)
                    setOtpError(false)
                    if (e.target.value.length >= 6) {
                      setDisableVerifyBtn(false)
                    } else {
                      setDisableVerifyBtn(true)
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      disableVerifyBtn ? undefined : onVerify()
                    }
                  }}
                />
              </Grid>
            </Grid>
          </form>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '8px' }}>
            <div
              className={style.signUpText}
              onClick={() => {
                sendOtp()
              }}
            >
              Resend OTP{'>'}
            </div>
            <div
              className={style.signUpText}
              onClick={() => {
                setOTPScreen(false)
                setCode('')
                setDisableVerifyBtn(true)
                if (otpError || errorType !== '') {
                  setErrorType('')
                  setOtpError(false)
                }
              }}
            >
              Wrong Number?
            </div>
          </div>

          <Grid item xs={12}>
            {otpError && (
              <div className={style.errorContainer}>
                <Typography sx={{ fontSize: '14px', fontWeight: 700 }}>
                  {errorType === 'Empty' && `Please enter passcode`}
                  {errorType === 'userExists' && `User already exists`}
                  {errorType === 'Invalid' && `Invalid Code`}
                  {errorType === 'Code_Expired' && `Oops! code expired, Click Resend`}
                  {errorType === 'Error_Occurred' && `Oops! something went wrong`}
                </Typography>
                <div
                  style={{
                    display: 'flex',
                    flex: '1',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    paddingRight: '12px',
                  }}
                >
                  <img
                    onClick={() => {
                      setOtpError(false)
                    }}
                    style={{ cursor: 'pointer' }}
                    src={'/images/wrong.svg'}
                  ></img>
                </div>
              </div>
            )}
          </Grid>
          {modal && (
            <Dialog onClose={() => {}} open={modal} PaperProps={{ style: styless }} className={style.modalPopUp}>
              <DialogTitle style={{ fontSize: '14.5px', marginTop: '0rem', marginLeft: '0.5rem' }}>
                You’ve previously signed up. Please download the KIRO mobile app to continue.
              </DialogTitle>
              <Button
                color="primary"
                style={{
                  width: 'fit-content',
                  marginBottom: '1rem',
                  height: '2rem',
                  padding: '0px 8px 0px 8px',
                  fontSize: '12px',
                }}
                onClick={() => {
                  navigate(`/offers/${id}/download`)
                }}
              >
                Ok
              </Button>
            </Dialog>
          )}
        </div>
        <Footer
          buttonText={'Verify'}
          isLoading={false}
          trackerIndex={2}
          shouldShowFive={true}
          shouldShowSix={true}
          isDisabled={disableVerifyBtn}
          buttonId={'sign-in-button'}
          buttonType={'submit'}
          onButtonClick={() => {
            disableVerifyBtn ? undefined : onVerify()
          }}
        ></Footer>
      </div>
    )
  }
}

export default Signup
